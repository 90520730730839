import { Navigate } from 'react-router-dom';
// import { lazy } from 'react';

// frontend pages
import MainLayout from 'src/components/layouts/MainLayout';
import LoginPage from 'src/pages/auth/LoginPage';
import NotFound from 'src/pages/NotFound';
import Register from 'src/pages/auth/Register';
import ResetPassword from 'src/pages/auth/ResetPassword';
import NewPassword from 'src/pages/auth/NewPassword';
import Pricing from 'src/pages/Pricing';
import ContentPage from 'src/pages/ContentPage';
import HomePage from 'src/pages/HomePage';

// admin dashboard
import DashboardLayout from './components/layouts/DashboardLayout';
import Upgrade from 'src/pages/Upgrade';
import Account from 'src/pages/account/Account';
import Dashboard from 'src/pages/dashboard/Dashboard';

import Settings from 'src/pages/settings/Settings';
import General from 'src/pages/settings/General';
import Menu from 'src/pages/settings/Menu';
import Button from 'src/pages/settings/Button';
import Integrations from 'src/pages/settings/Integrations';
import Forms from 'src/pages/settings/Forms';
import Emails from 'src/pages/settings/Emails';
import PromptSettings from 'src/pages/settings/PromptSettings';
import LiveChats from 'src/pages/settings/LiveChats';
import LiveChatWidget from 'src/pages/settings/LiveChatWidget';
import PromptMessages from 'src/pages/settings/PromptMessages';
import MenuItems from 'src/pages/settings/MenuItems';
import InboxPage from 'src/pages/dashboard/InboxPage';
import WidgetCode from 'src/pages/dashboard/WidgetCode';
import Widgets from 'src/pages/dashboard/Widgets';
import ChatThread from 'src/pages/dashboard/ChatThread';
import ChatThreadArchive from './pages/dashboard/ChatThreadArchive';
import LiveChatSettings from './pages/settings/LiveChatSettings';
import AffiliatePage from './pages/dashboard/AffiliatePage';

import ShopifyConnect from 'src/components/shopify/ShopifyConnect';
import ShopifyInstall from 'src/components/shopify/ShopifyInstall';
import ShopifyCharge from 'src/components/shopify/ShopifyCharge';

import WixConnect from 'src/components/wix/WixConnect';

import ChatFlowList from 'src/pages/chatbot/ChatFlowList';
import ChatFlow from 'src/pages/chatbot/ChatFlow';
import ChatFlowEdit from 'src/pages/chatbot/ChatFlowEdit';

import Roadmap from './pages/Roadmap';
import LiveChatAgents from './pages/settings/LiveChatAgents';
import Channels from './pages/settings/Channels';

import Agency from './pages/dashboard/Agency';
import LiveChatIntegrationsPage from './pages/settings/LiveChatIntegrations';
import SettingsLayout from './components/layouts/SettingsLayout';
import AgencySettings from './pages/settings/AgencySettings';
import QuickReplies from './pages/settings/QuickReplies';
import Notifications from './pages/settings/Notifications';
import DedicatedLiveChatPage from './pages/DedicatedLiveChatPage';
import MinimalLayout from './components/layouts/MinimalLayout';
import ContactsPage from './pages/dashboard/ContactsPage';
import ContactPage from './pages/dashboard/ContactPage';
import Version from './pages/Version';
import Appsumo from './pages/Appsumo';
import LifeTimeDeal from './pages/LifeTimeDeal';
import PaymentSuccess from './pages/PaymentSuccess';
import PlansComparison from './pages/PlansComparison';
import OldUpgrade from './pages/OldUpgrade';
import ChangelogPage from './pages/ChangelogPage';
import FaqPage from './pages/FaqPage';
import Visitors from './pages/Visitors';
import StartNewChat from './pages/dashboard/StartNewChat';
import ChatBotDriven from './pages/dashboard/ChatBotDriven';
import FeaturesPage from './pages/FeaturesPage';
import FeaturePage from './pages/FeaturePage';
import EmailTemplates from './pages/settings/EmailTemplates';
import Departments from './pages/settings/Departments';
import Tags from './pages/settings/Tags';
import OrganizationsPage from './pages/dashboard/OrganizationsPage';
import TicketsPage from './pages/dashboard/TicketsPage';
import TasksPage from './pages/dashboard/TasksPage';
import MeetingsPage from './pages/dashboard/MeetingsPage';
import OrganizationPage from './pages/dashboard/OrganizationPage';
import TicketPage from './pages/dashboard/TicketPage';
import MeetingPage from './pages/dashboard/MeetingPage';
import TaskPage from './pages/dashboard/TaskPage';
import PublicTicketPage from './pages/PublicTicketPage';
import KnowledgeBasePage from './pages/dashboard/KnowledgeBasePage';
import KbSettingsPage from './pages/settings/KbSettingsPage';
import KnowledgeBaseViewPage from './pages/dashboard/KnowledgeBaseViewPage';
import WorkspaceInviteAccept from './pages/WorkspaceInviteAccept';
import AgencyPrices from './pages/settings/AgencyPrices';
import AgencyPrice from './pages/settings/AgencyPrice';
import ConversationsSettings from './pages/settings/ConversationsSettings';
import AgencyPricingTables from './pages/settings/AgencyPricingTables';
import AccountLayout from './components/layouts/AccountLayout';
import AccountPlan from './pages/account/AccountPlan';
import AccountUsage from './pages/account/AccountUsage';
import AccountNotifications from './pages/account/AccountNotifications';
import Assistants from './pages/settings/Assistants';
import AccountBilling from './pages/account/AccountBilling';

const routes = (isAuth) => {
  const back = encodeURIComponent(window.location.pathname + window.location.search);
  const loginBackUrl = `/login?back=${back}`;
  let dashboardUrl = '/app';
  const b = new URLSearchParams(window.location.search).get('back');
  if (b) {
    dashboardUrl = b;
  }

  return [
    {
      path: 'app',
      element: isAuth ? <DashboardLayout /> : <Navigate to={loginBackUrl} />,
      children: [
        { path: '', element: isAuth ? <Dashboard /> : <Navigate to={loginBackUrl} /> },
        { path: 'inbox', element: isAuth ? <InboxPage /> : <Navigate to={loginBackUrl} /> },
        { path: 'inbox/:formID', element: isAuth ? <InboxPage /> : <Navigate to={loginBackUrl} /> },
        // { path: 'account', element: isAuth ? <Account /> : <Navigate to={loginBackUrl} /> },
        { path: 'dashboard', element: isAuth ? <Dashboard /> : <Navigate to={loginBackUrl} /> },
        { path: 'upgrade/:setId', element: isAuth ? <Upgrade /> : <Navigate to={loginBackUrl} /> },
        { path: 'upgrade', element: isAuth ? <Upgrade /> : <Navigate to={loginBackUrl} /> },
        { path: 'old-upgrade', element: isAuth ? <OldUpgrade /> : <Navigate to={loginBackUrl} /> },
        { path: 'plans-comparison/:setId', element: isAuth ? <PlansComparison /> : <Navigate to={loginBackUrl} /> },
        { path: 'plans-comparison', element: isAuth ? <PlansComparison /> : <Navigate to={loginBackUrl} /> },
        { path: 'shopify-connect', element: <ShopifyConnect /> },
        { path: 'shopify-install', element: <ShopifyInstall /> },
        { path: 'shopify-charge', element: <ShopifyCharge /> },
        { path: 'wix-connect', element: <WixConnect /> },
        { path: 'chat', element: <ChatThread /> },
        { path: 'chat/archive', element: <ChatThreadArchive /> },
        { path: 'chat/archive/:threadId', element: <ChatThreadArchive /> },
        { path: 'chat/bot-driven', element: <ChatBotDriven /> },
        { path: 'chat/bot-driven/:threadId', element: <ChatBotDriven /> },
        { path: 'chat/start/:connectionId', element: <StartNewChat /> },
        { path: 'chat/:threadId', element: <ChatThread /> },
        { path: 'chat-agents', element: isAuth ? <Navigate to="/settings/chat-agents" /> : <Navigate to={loginBackUrl} /> },
        { path: 'widgets', element: isAuth ? <Navigate to="/settings/widgets" /> : <Navigate to={loginBackUrl} /> },
        { path: 'apps', element: isAuth ? <Navigate to="/settings/apps" /> : <Navigate to={loginBackUrl} /> },
        { path: 'affiliate', element: isAuth ? <AffiliatePage /> : <Navigate to={loginBackUrl} /> },
        { path: 'agency', element: isAuth ? <Navigate to="/settings/agency-clients" /> : <Navigate to={loginBackUrl} /> },
        { path: 'contacts', element: isAuth ? <ContactsPage /> : <Navigate to={loginBackUrl} /> },
        { path: 'visitors', element: isAuth ? <Visitors /> : <Navigate to={loginBackUrl} /> },
        { path: 'contacts/:id', element: isAuth ? <ContactPage /> : <Navigate to={loginBackUrl} /> },
        { path: 'contacts/:id/:tab', element: isAuth ? <ContactPage /> : <Navigate to={loginBackUrl} /> },
        { path: 'tickets', element: isAuth ? <TicketsPage /> : <Navigate to={loginBackUrl} /> },
        { path: 'tickets/:id', element: isAuth ? <TicketPage /> : <Navigate to={loginBackUrl} /> },
        { path: 'tickets/:id/:tab', element: isAuth ? <TicketPage /> : <Navigate to={loginBackUrl} /> },
        { path: 'tasks', element: isAuth ? <TasksPage /> : <Navigate to={loginBackUrl} /> },
        { path: 'tasks/:id', element: isAuth ? <TaskPage /> : <Navigate to={loginBackUrl} /> },
        { path: 'tasks/:id/:tab', element: isAuth ? <TaskPage /> : <Navigate to={loginBackUrl} /> },
        { path: 'meetings', element: isAuth ? <MeetingsPage /> : <Navigate to={loginBackUrl} /> },
        { path: 'meetings/:id', element: isAuth ? <MeetingPage /> : <Navigate to={loginBackUrl} /> },
        { path: 'meetings/:id/:tab', element: isAuth ? <MeetingPage /> : <Navigate to={loginBackUrl} /> },
        { path: 'version', element: isAuth ? <Version /> : <Navigate to={loginBackUrl} /> },
        { path: 'payment-success', element: isAuth ? <PaymentSuccess /> : <Navigate to={loginBackUrl} /> },
        { path: 'features', element: isAuth ? <FeaturesPage /> : <Navigate to={loginBackUrl} /> },
        { path: 'features/:guid', element: isAuth ? <FeaturePage /> : <Navigate to={loginBackUrl} /> },
        { path: 'bugs', element: isAuth ? <FeaturesPage /> : <Navigate to={loginBackUrl} /> },
        { path: 'bugs/:guid', element: isAuth ? <FeaturePage /> : <Navigate to={loginBackUrl} /> },
        { path: 'organizations', element: isAuth ? <OrganizationsPage /> : <Navigate to={loginBackUrl} /> },
        { path: 'organizations/:id', element: isAuth ? <OrganizationPage /> : <Navigate to={loginBackUrl} /> },
        { path: 'organizations/:id/:tab', element: isAuth ? <OrganizationPage /> : <Navigate to={loginBackUrl} /> },
        { path: 'knowledge-base/edit', element: isAuth ? <KnowledgeBasePage /> : <Navigate to={loginBackUrl} /> },
        { path: 'knowledge-base/edit/:id', element: isAuth ? <KnowledgeBasePage /> : <Navigate to={loginBackUrl} /> },
        { path: 'knowledge-base/edit/:id/:version', element: isAuth ? <KnowledgeBasePage /> : <Navigate to={loginBackUrl} /> },
        { path: 'knowledge-base', element: isAuth ? <KnowledgeBaseViewPage /> : <Navigate to={loginBackUrl} /> },
        { path: 'knowledge-base/:id', element: isAuth ? <KnowledgeBaseViewPage /> : <Navigate to={loginBackUrl} /> },
        { path: 'accept-invite', element: isAuth ? <WorkspaceInviteAccept accept /> : <Navigate to={loginBackUrl} /> },
        { path: 'reject-invite', element: isAuth ? <WorkspaceInviteAccept accept={false} /> : <Navigate to={loginBackUrl} /> },
        { path: '*', element: <NotFound /> }
      ]
    },
    {
      path: 'chatbot',
      element: isAuth ? <DashboardLayout /> : <Navigate to={loginBackUrl} />,
      children: [
        { path: 'flows', element: isAuth ? <ChatFlowList /> : <Navigate to={loginBackUrl} /> },
        { path: 'flows/:id', element: isAuth ? <ChatFlow /> : <Navigate to={loginBackUrl} /> },
        { path: 'flows/:id/edit', element: isAuth ? <ChatFlowEdit /> : <Navigate to={loginBackUrl} /> }
      ]
    },
    {
      path: '/app/account',
      element: isAuth ? <AccountLayout /> : <Navigate to={loginBackUrl} />,
      children: [
        { path: '', element: isAuth ? <Account /> : <Navigate to={loginBackUrl} /> },
        { path: 'plan', element: isAuth ? <AccountPlan /> : <Navigate to={loginBackUrl} /> },
        { path: 'usage', element: isAuth ? <AccountUsage /> : <Navigate to={loginBackUrl} /> },
        { path: 'billing', element: isAuth ? <AccountBilling /> : <Navigate to={loginBackUrl} /> },
        { path: 'notifications', element: isAuth ? <AccountNotifications /> : <Navigate to={loginBackUrl} /> },
      ]
    },
    {
      path: 'settings',
      element: isAuth ? <SettingsLayout /> : <Navigate to={loginBackUrl} />,
      children: [
        { path: '', element: isAuth ? <Settings /> : <Navigate to={loginBackUrl} /> },
        { path: 'advanced', element: isAuth ? <General /> : <Navigate to={loginBackUrl} /> },
        { path: 'assistants', element: isAuth ? <Assistants /> : <Navigate to={loginBackUrl} /> },
        { path: 'button', element: isAuth ? <Button /> : <Navigate to={loginBackUrl} /> },
        { path: 'menu', element: isAuth ? <Menu /> : <Navigate to={loginBackUrl} /> },
        { path: 'integrations', element: isAuth ? <Integrations /> : <Navigate to={loginBackUrl} /> },
        { path: 'forms', element: isAuth ? <Forms /> : <Navigate to={loginBackUrl} /> },
        { path: 'emails', element: isAuth ? <Emails /> : <Navigate to={loginBackUrl} /> },
        { path: 'email-templates', element: isAuth ? <EmailTemplates /> : <Navigate to={loginBackUrl} /> },
        { path: 'tags', element: isAuth ? <Tags /> : <Navigate to={loginBackUrl} /> },
        { path: 'prompts', element: isAuth ? <PromptSettings /> : <Navigate to={loginBackUrl} /> },
        { path: 'live-chats', element: isAuth ? <LiveChats /> : <Navigate to={loginBackUrl} /> },
        { path: 'live-chat-widget', element: isAuth ? <LiveChatWidget /> : <Navigate to={loginBackUrl} /> },
        { path: 'live-chat-settings', element: isAuth ? <LiveChatSettings /> : <Navigate to={loginBackUrl} /> },
        { path: 'live-chat-integrations', element: isAuth ? <LiveChatIntegrationsPage /> : <Navigate to={loginBackUrl} /> },
        { path: 'live-chat-quick-replies', element: isAuth ? <QuickReplies /> : <Navigate to={loginBackUrl} /> },
        { path: 'prompts', element: isAuth ? <PromptMessages /> : <Navigate to={loginBackUrl} /> },
        { path: 'menu-items', element: isAuth ? <MenuItems /> : <Navigate to={loginBackUrl} /> },
        { path: 'channels', element: isAuth ? <Channels /> : <Navigate to={loginBackUrl} /> },
        { path: 'conversations', element: isAuth ? <ConversationsSettings /> : <Navigate to={loginBackUrl} /> },
        { path: 'chat-agents', element: isAuth ? <LiveChatAgents /> : <Navigate to={loginBackUrl} /> },
        { path: 'departments', element: isAuth ? <Departments /> : <Navigate to={loginBackUrl} /> },
        { path: 'agency-clients', element: isAuth ? <Agency /> : <Navigate to={loginBackUrl} /> },
        { path: 'agency-prices/:setId/:planId', element: isAuth ? <AgencyPrice /> : <Navigate to={loginBackUrl} /> },
        { path: 'agency-prices/:setId', element: isAuth ? <AgencyPrices /> : <Navigate to={loginBackUrl} /> },
        { path: 'agency-prices', element: isAuth ? <AgencyPricingTables /> : <Navigate to={loginBackUrl} /> },
        { path: 'agency', element: isAuth ? <AgencySettings /> : <Navigate to={loginBackUrl} /> },
        { path: 'widgets', element: isAuth ? <Widgets /> : <Navigate to={loginBackUrl} /> },
        { path: 'apps', element: isAuth ? <WidgetCode /> : <Navigate to={loginBackUrl} /> },
        { path: 'notifications', element: isAuth ? <Notifications /> : <Navigate to={loginBackUrl} /> },
        { path: 'knowledge-base', element: isAuth ? <KbSettingsPage /> : <Navigate to={loginBackUrl} /> },
        { path: '*', element: <NotFound /> }
      ]
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { path: 'login', element: isAuth ? <Navigate to={dashboardUrl} /> : <LoginPage /> },
        { path: 'register', element: isAuth ? <Navigate to={dashboardUrl} /> : <Register /> },
        { path: 'reset-password', element: isAuth ? <Navigate to={dashboardUrl} /> : <ResetPassword /> },
        { path: 'new-password', element: isAuth ? <Navigate to={dashboardUrl} /> : <NewPassword /> },
        { path: 'pricing', element: <Pricing /> },
        { path: 'secret-ltd', element: <LifeTimeDeal /> },
        { path: 'terms-of-service', element: <ContentPage contentUrl="/content/tos" title="Terms of service | AnyChat" /> },
        { path: 'privacy-policy', element: <ContentPage contentUrl="/content/privacy" title="Privacy Policy | AnyChat" /> },
        { path: 'dpa', element: <ContentPage contentUrl="/content/dpa" title="Data protection | AnyChat" /> },
        { path: 'gdpr', element: <ContentPage contentUrl="/content/gdpr" title="GDPR | AnyChat" /> },
        { path: 'sub-processors', element: <ContentPage contentUrl="/content/sub-processors" title="Sub-processors | AnyChat" /> },
        { path: 'shopifyconnect', element: <ShopifyInstall /> },
        { path: 'shopify-connect', element: <ShopifyConnect /> },
        { path: 'roadmap', element: <Roadmap /> },
        { path: 'changelog', element: <ChangelogPage /> },
        { path: 'appsumo/:code', element: <Appsumo /> },
        { path: 'faq', element: <FaqPage /> },
        { path: '404', element: <NotFound /> },
        { path: '/', element: <HomePage /> },
        { path: '*', element: <NotFound /> }
      ]
    },
    {
      path: '/',
      element: <MinimalLayout />,
      children: [
        { path: '/chat/:id', element: <DedicatedLiveChatPage /> },
        { path: '/ticket/:id', element: <PublicTicketPage /> },
      ]
    }
  ];
};

export default routes;
