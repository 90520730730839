import { lazy, Suspense } from 'react';
import {
  Box,
  Container,
} from '@mui/material';
import LoadingProgress from 'src/components/app/LoadingProgress';
import PageMeta from 'src/components/app/PageMeta';
import { useTranslation } from 'react-i18next';
import { useAppConext } from 'src/components/context/AppContext';
import NoContent from 'src/components/app/NoContent';
import { Lock } from '@mui/icons-material';
const Content = lazy(() => import('../../components/account/AccountPlan'));

const AccountPlan = () => {
  const { t } = useTranslation();
  
  const { user } = useAppConext();

  if (user?.rootUser === false) {
      return (
          <Box sx={{ p: 2, height: 'calc(100vh - 96px)', display: 'flex' }}>
              <NoContent
                  containerSx={{
                      my: 'auto'
                  }}
                  icon={<Lock color="error" sx={{ color: 'error', width: 40, height: 40 }} />}
                  title={t('Forbidden')}
                  content={t("You're don't have access to this page")}
              />
          </Box>
      );
  }

  return (
    <>
      <PageMeta title={t('Account')} />
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
        }}
      >
          <Suspense fallback={<LoadingProgress open />}>
            <Content />
          </Suspense>
      </Box>
    </>
  );
};

export default AccountPlan;